import axios from "axios";
import { SET_ALERT } from "../alert";

export default {
  actions: {
    addDocuments({ commit, dispatch }, form) {
      axios
        .post("api/documents", form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          commit(SET_ALERT, {
            message: "Documentos agregados correctamente",
            type: "success",
          });
          if (form.get("type") == "loan") {
            dispatch("getLoan", form.get("id"));
          } else {
            dispatch("getSale", form.get("id"));
          }
        })
        .catch((error) => {
          if (error.response.data) {
            var message;
            error.response.data.errors
              ? (message =
                  error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ][0])
              : (message = error.response.data.message);

            commit(SET_ALERT, {
              message: message,
              type: "error",
            });
          }
        });
    },
    deleteDocuments({ commit, dispatch }, form) {
      axios
        .post("api/documents/" + form.key, {
          id: form.id,
          type: form.type,
          _method: "DELETE",
        })
        .then(() => {
          commit(SET_ALERT, {
            message: "Documento eliminado correctamente",
            type: "success",
          });
          if (form.type) {
            dispatch("getLoan", form.id);
          } else {
            dispatch("getSale", form.id);
          }
        })
        .catch((error) => {
          if (error.response.data) {
            var message;
            error.response.data.errors
              ? (message =
                  error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ][0])
              : (message = error.response.data.message);

            commit(SET_ALERT, {
              message: message,
              type: "error",
            });
          }
        });
    },
  },
};
