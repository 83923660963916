import axios from "axios";
import { SET_ALERT } from "../alert";

export const SET_LOADING = "SET_LOADING";
export const SET_DRESSES = "SET_DRESSES";
export const SET_DATES_LOANS = "SET_DATES_LOANS";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_LOADING_DATES = "SET_LOADING_DATES";

export default {
  state: {
    dresses: [],
    datesLoans: [],
    currentPage: 1,
    isLoading: false,
    isLoadingDates: false,
  },
  mutations: {
    [SET_LOADING](state, loading) {
      state.isLoading = loading;
    },
    [SET_DRESSES](state, dresses) {
      state.dresses = dresses;
    },
    [SET_CURRENT_PAGE](state, page) {
      state.currentPage = page;
    },
    [SET_DATES_LOANS](state, datesLoans) {
      state.datesLoans = datesLoans;
    },
    [SET_LOADING_DATES](state, loading) {
      state.isLoadingDates = loading;
    },
  },
  actions: {
    getDresses({ commit, state }, filters) {
      if (filters == undefined) filters = "";
      commit(SET_LOADING, true);
      axios
        .get("api/dresses?" + filters + "page=" + state.currentPage)
        .then((response) => {
          commit(SET_LOADING, false);
          commit(SET_DRESSES, response.data);
        })
        .catch((error) => {
          commit(SET_LOADING, false);
          if (error.response.data) {
            commit(SET_ALERT, {
              message: error.response.data.message,
              type: "error",
            });
          }
        });
    },

    getDressesByCategory({ commit }, payload) {
      var params;
      if (typeof payload === "object") {
        params = "";
        if (payload.category_id) {
          params = "category_id=" + payload.category_id;
        }
        if (payload.event_date) {
          params += "&event_date=" + payload.event_date;
        }
        if (payload.return_date) {
          params += "&return_date=" + payload.return_date;
        }
      } else {
        params = "category_id=" + payload + "&process_type=sale";
      }

      axios
        .get("api/dresses?status=ACTIVE&" + params)
        .then((response) => {
          commit(SET_DRESSES, response.data);
        })
        .catch((error) => {
          if (error.response.data) {
            commit(SET_ALERT, {
              message: error.response.data.message,
              type: "error",
            });
          }
        });
    },

    createDress({ commit, dispatch }, form) {
      commit(SET_LOADING, true);
      axios
        .post("api/dresses", form)
        .then(() => {
          commit(SET_LOADING, false);
          commit(SET_ALERT, {
            message: "Vestido creado correctamente",
            type: "success",
          });
          dispatch("getDresses");
        })
        .catch((error) => {
          commit(SET_LOADING, false);
          if (error.response.data) {
            var message;
            error.response.data.errors
              ? (message =
                  error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ][0])
              : (message = error.response.data.message);

            commit(SET_ALERT, {
              message: message,
              type: "error",
            });
          }
        });
    },

    updateDress({ commit, dispatch }, payload) {
      commit(SET_LOADING, true);
      axios
        .post("api/dresses/" + payload.id, payload.form)
        .then(() => {
          commit(SET_LOADING, false);
          commit(SET_ALERT, {
            message: "Vestido editado correctamente",
            type: "success",
          });
          dispatch("getDresses");
        })
        .catch((error) => {
          commit(SET_LOADING, false);
          if (error.response.data) {
            commit(SET_ALERT, {
              message: error.response.data.message,
              type: "error",
            });
          }
        });
    },

    getDress({ commit }, id) {
      commit(SET_LOADING, true);
      return axios
        .get("api/dresses/" + id)
        .then((response) => {
          commit(SET_LOADING, false);
          return response.data;
        })
        .catch((error) => {
          commit(SET_LOADING, false);
          if (error.response.data) {
            commit(SET_ALERT, {
              message: error.response.data.message,
              type: "error",
            });
          }
        });
    },

    getDatesOfLoansAndPhotoStudio({ commit }, id) {
      commit(SET_LOADING_DATES, true);
      axios
        .get("api/loan_dresses/" + id)
        .then((response) => {
          commit(SET_LOADING_DATES, false);
          commit(SET_DATES_LOANS, response.data);
        })
        .catch((error) => {
          commit(SET_LOADING_DATES, false);
          if (error.response.data) {
            commit(SET_ALERT, {
              message: error.response.data.message,
              type: "error",
            });
          }
        });
    },

    nextPageDresses({ commit, dispatch }, page) {
      commit(SET_CURRENT_PAGE, page);
      dispatch("getDresses");
    },
  },
};
